import 'script-loader!../src/vendor/slick'

$(document).on('turbolinks:load', function() {

    $("#search-section #categories .category").click(function(){
        var wrapper             = $(this).closest(".wrapper")
        var arrow               = wrapper.find('i.arrow').first()
        var items_list          = wrapper.find('.items').first()
        var selected_items_list = wrapper.find('.selected-items').first()

        if (items_list.is(":hidden")) {
            arrow.css("transform", "rotate(180deg)").addClass("open")
            items_list.show()
        } else if (items_list.is(":visible")) {
            arrow.css("transform", "rotate(0deg)").removeClass("open")
            items_list.hide()
        }

        if (selected_items_list.is(":hidden")) {
            selected_items_list.show()
        } else if (selected_items_list.is(":visible")) {
            selected_items_list.hide();
        }
    })

    $("#search-section #categories .tag").click(function(){
        var wrapper     = $(this).closest(".wrapper")
        var arrow       = wrapper.find('i.arrow').first()
        var items_list  = wrapper.find('.items').first()
        var tag_id      = $(this).attr("tag_id")
        var tag_title   = $(this).text()
        var item        = "<div class='selected-tag flex flex-row space-x-12 items-center justify-between' tag_id='"+tag_id+"'><div>"+tag_title+"</div><i class='fas fa-times'></i></div>";

        if (items_list.is(":hidden")) {
            arrow.css("transform", "rotate(180deg)").addClass("open")
        } else if (items_list.is(":visible")) {
            arrow.css("transform", "rotate(0deg)").removeClass("open")
        }

        if($(".selected-tag[tag_id="+tag_id+"]").length === 0)
            $(this).closest('.items').siblings('.selected-items').append(item)

        if($(this).hasClass("selected")){
            $(this).removeClass("selected")
            $(".selected-tag[tag_id="+tag_id+"]").remove()
        } else
            $(this).addClass("selected")
    })

    $(document).on("click", ".selected-tag i", function(){
        $(this).closest(".selected-tag").remove()
    });

    $(document).on("click", ".expand", function(){
        $(this).closest(".image-wrapper").find("img").click()
    })

    $(document).on("click", ".image-tag-item", function(){
        $(this).toggleClass("selected-image-tag")
    })
});